// Imports
import { mapboxgl } from "configs/mapbox";
import { LAYERS } from "constants";
import React from "react";
import ReactDOM from "react-dom/client";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Supercluster from "supercluster";
import moment from "moment";
import { auth } from "configs/firebase";
import { toast } from "react-toastify";
import { ROLES } from "constants/global";
import { clearUser } from "redux/Slices/UserSlice";
import { logoutUser } from "redux/Slices/AuthSlice";
import { fetchMarkerData } from "utils/fetchMarkerData";
import { fetchCoordData } from "utils/fetchCoordData";
import {fetchCenterCoordinates} from "utils/fetchCenterCoordinates"
import Overlay from "components/Common/overlay/Overlay";
import Header from "../../components/Common/header/header";
import CarouselComponent from "components/UIComponent/Carousel";
import LoginForm from "components/LoginForm/loginForm";
import "./styles.css";
import TooltipBar from "./popups/TooltipBar";
import TogglePopup from "./popups/TogglePopup";
import { id } from "date-fns/locale";
import { fetchDeviceInfo } from "utils/fetchDeviceInfo";
// import { allimages } from "allImages";
// Main Component
const Dashboard = () => {
  const IMEIs=["xHtssg6Ns6wP93MlOzWi","u8bCTxPY2lu8Xud0Wj9b","iRVjJw1ign9cz4ZlmUEY","3HKboJqjMFD4qkUylfYA","z6OeFJ0HTkOa1zdfr6lb","ZkG150t2scLZs9JKK0Wv","zziSvNb4ekXfX3ob5YE7","ebGFn3C2cGhv8tnZNsWK","0C7Fk3uNZAfz6m0ISm1h","o7dO5vmO9iHO2tHdM1qY","emqrgiwOBSHW4dF6c4el","JXVcrQ4ZQWq1ZMonqi4K","n0UCeYquuNaJFnxo0L9c","fo0Ki7ZRpWzk3z5eMbP8"]
  const map = useRef(null);
  const mapContainer = useRef(null);
  let 
    zoom = 8;
  const [loading, setLoading] = useState(true);
  const [currentElement, setCurrentElement] = useState();
  const user = useSelector((state) => state.entity.userReducer.user);
  const [markerDataLoading, setMarkerDataLoading] = useState(false);
  const [polygonDataLoading, setPolygonDataLoading] = useState(false);
  const [triggerOverlay, setTriggerOverlay] = useState(false);
  const dispatch = useDispatch();
  const [fetchedData, setFetchedData] = useState(null);
  const [selectedImageMode, setSelectedImageMode] = useState("");
  const [currentMarkers, setCurrentMarkers] = useState("");
  const [imageFilterMarkers, setImageFilterMarkers] = useState("images");
  const [clickedMarker, setClickedMarker] = useState({});
  const [isRenderPolygon, setIsRenderPolygon] = useState(false);
  const [isPopupDragging, setIsPopupDragging] = useState(true);
  const [isAtStart, setStart] = useState(true); //add new
  const [currentSegments, setCurrentSegments] = useState("");
  const popupRefs = {}; // Create an object to store popup references
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [imgGallery, setImgGallery] = useState(false);
  const [isToggleChanged, setToggleChanged] = useState(false);
  const markersRef = useRef([]);
  const copiedMarkerRef = useRef([]);
  const superCluster = useRef(null);
  const [currentDate] = useState(new Date());
  const [timeoutId, setTimeoutId] = useState(null);
  const [toggledMarker, setToggledMarker] = useState(" ");
  const [tempData, setTempData] =  useState({});
  const [TooltipBarConfig,setTooltipBarConfig]=useState(false)
  let imageId=[]
  let offset = [0, 0];
  let [layerIndex, setLayerIndex] = useState(0);
  let [lat, setLat] = useState(0);
  let [lng, setLng] = useState(0);
 // const mapLayers = [
  //   { url: LAYERS.ILLUMINATED1 },
  //   { url: LAYERS.ILLUMINATED2 },
  //   { url: LAYERS.ILLUMINATED4 },
  // ];
  const mapLayers = [
    { url: LAYERS.SETALLITEV9 },
    { url: LAYERS.STREETV11 },
    { url: LAYERS.SETALLITEV12 },
  ];
  const [closeOverlay, setCloseOverlay] = useState(false);
  // Start Date setting to 1st of current Month & End Date to current Date
  const [startDate, setStartDate] = useState(
    moment(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
    ).format("YYYY-MM-DD")
  ); // Change this with your desired initial start date
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  ); // Change this with your desired initial end date
  const start = {
    center: [80, 36],
    zoom: 1,
    pitch: 0,
    bearing: 0,
    maxZoom:21
  };
  const end = {
    center: [lng, lat],
    zoom: zoom,
    maxZoom:21

  };
  const original = {
    // center: [80, 36],
    zoom: 1,
  };
  const [width, setWidth] = useState(window.innerWidth);
  const [isFormEdited, setIsFormEdited] = useState(false);
  
 
  /*  ----------------------- Functions -------------------------------------------------------- */

  const getCenterCoordinates=()=>{
    fetchCenterCoordinates().then(resp=>{setLat(resp?.latitude || 13.733521578120758);setLng(resp?.longitude || -88.87355776392715); end.center=[lng,lat]}).catch(error=>console.log("Error Fetching Center Coordinates",error))
  }
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      // removeSelectedMarker()
      if (triggerOverlay) {
      } else {
        removeSelectedMarker();
      }
    });
  }, [triggerOverlay]);
  //  const getCurrentElementOnEdit=(data)=>{
  //   setCurrentEditedEl(data)

  //  }
  //Function to get markervisibility value from TooltipBar
  const getVisibiltyToggle = (markerdata, visibility, check) => {
    if (currentMarkers === "client-markers" && visibility) {
      setToggledMarker(markerdata);
      map.current?._markers.forEach((marker) => {
        if (
          marker?._element?.id === markerdata?.id &&
          visibility === true &&
          check.clientVisibility === false
        ) {
          marker?.remove();
        }
      });
      setFetchedData(
        fetchedData.filter((marker) => {
          return marker?.id !== markerdata?.id;
        })
      );
    }
    setToggledMarker(undefined);
  };
  // Function for handle Form Submit
  function submitHandler(user) {
    getData(user);
    map.current.setStyle(LAYERS.SETALLITEV9);

    // getCoordinatesAndRenderPolygons(user.role);
    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
  }

  // Function to spin the Globe
  function spinGlobe() {
    const maxSpinZoom = 3;
   
    if (
      map.current?.getZoom() < maxSpinZoom &&
      map.current?.getProjection()?.name === "globe"
    ) {
      let distancePerSecond = 360 / 120;
      const center = map.current.getCenter();
      center.lng -= distancePerSecond;

      // Smoothly animate the map over one second.
      // When this animation is complete, it calls a 'moveend' event.
      map.current.easeTo({ center, duration: 1000, easing: (n) => n });
    }
  }

  // Function to Toggle the map View
  function toggleMapStyle() {
    setLayerIndex((layerIndex + 1) % mapLayers.length);
    const activeLayer = mapLayers[(layerIndex + 1) % mapLayers.length];
    const newStyle = activeLayer.url;
    console.log("newStyle:", newStyle);

    map.current.setStyle(newStyle);

    // getCoordinatesAndRenderPolygons();
    if (isRenderPolygon) getCoordinatesAndRenderPolygons(user.role);
  }

  function handleLogout() {
    try {
      auth.signOut();
      setFetchedData([]);
      currentElement?.getPopup()?.remove();
      setIsRenderPolygon(false);
      setCurrentElement(null);
      setTriggerOverlay(false);
      dispatch(logoutUser());
      map.current.setFog({
        color: "rgb(255, 255, 255)",
        "high-color": "rgb(255, 255, 255)",
        "horizon-blend": 0,
        "space-color": "rgb(255, 255, 255)",
      });
      dispatch(clearUser());
      map.current.setMaxZoom(21)
      document
      .querySelector(" .mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-in")
      ?.remove();
      document
        .querySelector(" .mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-out")
        ?.remove();
      document
        .querySelector(" .mapboxgl-ctrl-group .mapboxgl-ctrl-compass")
        ?.remove();
    } catch (error) {
      toast.error(error.message);
    }
  }

  // async function processChunk(chunk) {
  //   try {
  //     chunk.forEach((val) => {
  //       if(imgStore.items){
  //         const matchingItems = imgStore?.items.filter((itemRef) => val.id === itemRef.fullPath.split('/')[1]);
  //         if (matchingItems.length > 0) {
  //           filteredData.push(val);
  //         }
  //       }

  //     });
  //   } catch (error) {
  //     console.error('Error listing items:', error);
  //   }
  // }

  const isPointInElSalvador = (lat, lon) => {
    return (
      lat >= 13.1485 && lat <= 14.4511 && lon <= -87.6937 && lon >= -90.1344
    );
  };

  function containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
  }
  // Function to fetch data from firebase
  const getData = async (user) => {
    let data, clientMarkers;
    map.current && map.current.setBearing(0);
    setMarkerDataLoading(true);

    try {
      if (user.role === ROLES.ADMIN) {
        let imgMode;
        imageFilterMarkers === "all"
          ? (imgMode = "all")
          : imageFilterMarkers === "images"
          ? (imgMode = "images")
          : (imgMode = "no-images");

        data = await fetchMarkerData(
          user.role,
          selectedImageMode,
          startDate,
          endDate,
          currentMarkers,
          imgMode
        );
      } else if (user.role === ROLES.USER) {
        const pin = containsOnlyNumbers(user.email.split(".")[0]);
        let deviceDataIds = [];
        // console.log((user.email).split('.')[0],pin)
        if (pin != false) {
          const data_ = await fetchDeviceInfo(user.role);
          const deviceData = data_.filter(
            (val) => val?.pin == user.email.split(".")[0]
          );
          deviceDataIds = deviceData.map((x) => x.id);
        }
        clientMarkers = await fetchMarkerData(
          user.role,
          selectedImageMode,
          "2023-12-01",
          endDate,
          "",
          "all",
          pin != false ? deviceDataIds : []
        );

        data = clientMarkers;
      }
      setFetchedData(data);

      let xyz = {};
      data.forEach((item) => {
        if (!xyz[item.location]) xyz[item.location] = [item];
        else xyz[item.location] = [...xyz[item.location], item];
      });
      let arrayUniqueByKey = [
        ...new Map(data.map((item) => [item["location"], item])).values(),
      ];
      if(user.role === ROLES.USER)
      arrayUniqueByKey=arrayUniqueByKey.filter(markerData=>!IMEIs.includes(markerData.mobileImeiReference))
      setTempData(xyz);
      setFetchedData(arrayUniqueByKey);
    } catch (err) {
      toast.error(err);
    }
    setMarkerDataLoading(false);
  };
 
  // Fetch Cooridnates Data For Polygons
  const getCoordinatesAndRenderPolygons = async (role) => {
    setMarkerDataLoading(true);
    setPolygonDataLoading(true);
    let coord = await fetchCoordData(role, currentSegments);
    setMarkerDataLoading(false);
    if (coord) {
      const sourceData = coord.map((obj) => {
        return {
          id: obj.id,
          clientVisibility: obj.clientVisibility || false,
          coordinates: obj.coordinates.map((data) => [data._long, data._lat]),
        };
      });
      // if(coord.length>0)
      if (isRenderPolygon === true) {
        removePrevSegmentPopups();
        renderPolygons(role, sourceData);
      }
      setPolygonDataLoading(false);
    }
  };
  const togglePolygonStyle = () => {
    setIsRenderPolygon(!isRenderPolygon);
    const polygonIcon = document.getElementById("PolygonIcon");
    polygonIcon.classList.toggle("polygonIcon-selected");
  };
  function removePrevSegmentPopups() {
    map.current?._popups.forEach((popup) => {
      popup.remove();
    });
  }
  useEffect(() => {
    if (isRenderPolygon === true) {
      getCoordinatesAndRenderPolygons(user.role);
    } else if (isRenderPolygon === false) {
      removePolygons(map.current?.getStyle()?.layers);
      removePrevSegmentPopups();
    }
  }, [isRenderPolygon]);
  function removePolygons(allLayers) {
    allLayers?.forEach((layer) => {
      if (
        layer.id.startsWith("outlineLayer") ||
        layer.id.startsWith("regionLayer")
      ) {
        map.current.removeLayer(layer.id);
      }
    });
    const sourcesToRemove = map.current?.getStyle().sources || {};
    Object.keys(sourcesToRemove).forEach((sourceId) => {
      if (sourceId.startsWith("source")) {
        map.current.removeSource(sourceId);
      }
    });
  }
  // Function to Create a custom cluster element with the count of markers
  function createClusterElement(count, cluster, geometry, clusterobj) {
    var zoom = map?.current?.getZoom();
    var element = document.createElement("div");
    element.className = "cluster-marker";
    element.id = cluster;
    element.innerHTML = count;
    element.setAttribute("coordinates", JSON.stringify(geometry.coordinates));
    element.addEventListener("click", (e) => {
      const customValue = e.target.getAttribute("coordinates");
      const parsedCoord = JSON.parse(customValue);
      map.current?.flyTo({
        center: parsedCoord,
        zoom:
          map.current.getZoom() < 20
            ? map.current.getZoom() + 4
            : map.current.getZoom() + 1,
        essential: true,
      });
      // if(map.current.zoom()>=20 )
    });
    return element;
  }

  // Function to update clusters when User interacting  (Zoom/Pan)
  function updateClusters(supercluster) {
    var zoom = map?.current?.getZoom();
    // var bounds= map?.current?.getBounds()
    // var clusters = supercluster.getClusters(
    //   [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()],
    //   zoom
    // );
    var clusters = supercluster?.getClusters([-180, -90, 180, 90], zoom);
    // Clear existing markers from the map
    let count
    markersRef.current.forEach(function (marker) {
      marker.remove();
    });
    // Add the clustered markers to the map
    if (user && fetchedData.length && Object.keys(tempData).length) {
      clusters.forEach(function (cluster,index) {
        if (cluster.properties && cluster.properties.cluster) {
          count=getCount(cluster.id)
          if(count)
         { var clusterMarker = new mapboxgl.Marker({
            element: createClusterElement(
              count,
              cluster.properties.cluster_id,
              cluster.geometry,
              cluster
            ),
          })
            .setLngLat(cluster.geometry.coordinates)
            ?.addTo(map.current);
          markersRef.current.push(clusterMarker);}
        } else {
          var markerId = cluster.properties.marker_id;
          var marker = markersRef.current[markerId];
          if(marker.markerData)
          {

            if(tempData.hasOwnProperty(marker.markerData.location))
           {
            if(tempData[marker.markerData.location].length>1)
           { if(marker._element.classList.contains("white-marker"))
            {
              marker._element.classList.remove("white-marker")
              marker._element.classList.add("empty-marker")
        
            }
            else if(marker._element.classList.contains("purple-marker"))
            {
              marker._element.classList.remove("purple-marker")
              marker._element.classList.add("empty-marker")
            
            }
           else if(marker._element.classList.contains("yellow-marker"))
            {
              marker._element.classList.remove("yellow-marker")
              marker._element.classList.add("empty-marker")
       
            }
            let rotation=
              marker.markerData.capturedImageMode === "panorama" && marker?.markerData.panoramaImages?.length > 1
              ?  
              marker?.markerData.panoramaImages[1]?.heading 
              :
              marker.markerData.heading ? marker.markerData.heading : 0
            marker._element.innerHTML = `<div style="position: relative;
            display: flex;
            ">
            <p 
            className = "cluster-text"
            style="margin:auto;
            line-height:1;
            font-size:14px;
            transform:rotate(${360-rotation}deg);
            padding-right:
            ${
              (tempData[marker.markerData.location].length>11 && tempData[marker.markerData.location].length<20) || tempData[marker.markerData.location].length===10
              ?
              "1px"
              :
              "0px"
            };
            padding-left:
            ${
              tempData[marker.markerData.location].length%10===1 && tempData[marker.markerData.location].length!==11 ? "1px" : "0px"
            }
            ">
            ${tempData[marker.markerData.location].length}
            </p>
            </div>`;
          }
          marker?.addTo(map.current);
          }

          }
        }
      });
    }
  }
  const getCount=(clusterId)=>{
   let count=0
   let temp=[]
   let result =[]
   let final=[]
   let allLeaves=[]
   let childs=superCluster?.current?.getChildren(clusterId)
   let xyz=[]
   let myData=tempData
   let tempIndex;
   let leaves=superCluster?.current?.getLeaves(clusterId)
   let first =false
   let tempIndex1
  
    for(let i=0;i<childs.length;i++){
      if(childs[i].id)
        {
          result.push(childs[i].id)
          if(!final.includes(childs[i].id))
          final.push(childs[i].id)
            result.forEach(id=>
              {
                childs.push(...superCluster?.current?.getChildren(id))
                result.pop(id)
              })
        }
      else
        {
        temp.push(childs[i])
        }

    }

    final.forEach((id, number)=>{
     allLeaves.push(...superCluster?.current?.getLeaves(id))
     xyz=superCluster?.current?.getLeaves(id)
      for(let j=0;j<xyz.length;j++)
      {
        if(myData.hasOwnProperty(xyz[j].properties.marker_data.location))
          { 
            tempIndex=j
            break
          }
       }
       if(tempIndex===undefined)
          tempIndex=0  
      xyz.forEach((it,ind)=>
        {
          if(ind!==tempIndex && myData[xyz[tempIndex]?.properties?.marker_data?.location] && it?.properties?.marker_data?.location && myData[it?.properties?.marker_data?.location] && !myData[xyz[tempIndex]?.properties?.marker_data?.location].includes(it?.properties?.marker_data) )
            { 
              myData[xyz[tempIndex].properties.marker_data.location]=[...myData[xyz[tempIndex].properties.marker_data.location],...myData[it.properties.marker_data.location]]
              delete myData[it.properties.marker_data.location]
            }
        })
    })

    setTempData(myData)
    allLeaves=[...allLeaves,...temp]
    allLeaves.concat(leaves)
    allLeaves = [
      ...new Map(allLeaves.map((item) => [item.properties.marker_id, item])).values(),
    ];
   
    allLeaves.forEach((leave)=>
    {
      if(myData[leave?.properties?.marker_data?.location])
        { 
            if(!first)
              {
                tempIndex1=leave?.properties?.marker_data?.location
              }
            first=true
            count+=tempData[leave?.properties?.marker_data?.location].length
            if(map.current.getZoom()>=21 && myData[tempIndex1] && tempIndex1!==leave?.properties?.marker_data?.location)
              {  
                map.current.setMaxZoom(22); 
                myData[tempIndex1]=[...myData[tempIndex1],...myData[leave?.properties?.marker_data?.location]]
                delete myData[leave?.properties?.marker_data?.location]
              }
        }
      else
        {
          if(map.current.getZoom()>=21)
            map.current.setMaxZoom(22); 
        }
    })
  return count
  }




  function removePopup(id) {
    if (popupRefs[id]) {
      popupRefs[id].remove(); // Close the popup
      delete popupRefs[id]; // Remove the reference
    }
  }

  function renderPolygons(role, coordinates) {
    removePolygons(map.current?.getStyle()?.layers);
    coordinates.map((obj) => {
      const dataSource = {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {
            id: obj.id,
          },
          geometry: {
            type: "Polygon",
            coordinates: [obj.coordinates],
          },
        },
      };

      // If layers already existed then remove previous layers
      if (map.current.getLayer(`outlineLayer-${obj.id}`)) {
        map.current.removeLayer(`outlineLayer-${obj.id}`);
      }
      if (map.current.getLayer(`regionLayer-${obj.id}`)) {
        map.current.removeLayer(`regionLayer-${obj.id}`);
      }
      if (map.current.getSource(`sourceId-${obj.id}`)) {
        map.current.removeSource(`sourceId-${obj.id}`);
      }
      map.current.addSource(`sourceId-${obj.id}`, dataSource);
      map.current.addLayer({
        id: `regionLayer-${obj.id}`,
        type: "fill",
        source: `sourceId-${obj.id}`,
        layout: {},
        paint: {
          "fill-color": "#b565f7",
          "fill-opacity": 0.3,
        },
      });
      map.current.addLayer({
        id: `outlineLayer-${obj.id}`,
        type: "line",
        source: `sourceId-${obj.id}`,
        layout: {},
        paint: {
          "line-color": "#7DF9FF",
          "line-width": 3,
        },
      });
      map.current.on("mousemove", `regionLayer-${obj.id}`, (e) => {
        map.current.setPaintProperty(
          `regionLayer-${obj.id}`,
          "fill-color",
          "#a1dab4"
        );
      });
      map.current.on("mouseleave", `regionLayer-${obj.id}`, (e) => {
        map.current.setPaintProperty(
          `regionLayer-${obj.id}`,
          "fill-color",
          "#6f65f7"
        );
      });
      map.current.on("click", `regionLayer-${obj.id}`, (e) => {
        // let popup;

        const popupContent = (
          <TogglePopup
            role={role}
            id={obj.id}
            clientVisibility={obj.clientVisibility}
            sourceData={coordinates}
            currentSegments={currentSegments}
            onHandleChange={renderPolygons}
            onClose={removePopup}
            setToggleChanged={setToggleChanged}

            // popup={popup}
          />
        );
        const tempDOMElement = document.createElement("div");
        ReactDOM.createRoot(tempDOMElement).render(popupContent);
        tempDOMElement.classList.add("toggle-popup");
        const popup = new mapboxgl.Popup({ closeButton: false })
          .setLngLat(e.lngLat)
          .setDOMContent(tempDOMElement);
        // .setHTML(`<div style="background-color:white"><h3 style="color:blue;">hello</h3><p>world</p></div>`)
        removePrevSegmentPopups();
        popup.addTo(map.current);
        popupRefs[obj.id] = popup;
        // e.stopPropagation()
      });
    });
  }

  //  Function to call update cluster when user interacted

  function handleMoveEnd() {
    var allPopupsClosed = map.current?._markers?.every(function (marker) {
      return !marker?.getPopup()?.isOpen();
    });
    if (allPopupsClosed && user != null && map.current?._markers.length!=0) {
      updateClusters(superCluster?.current);
    }
  }

  function removeSelectedMarker() {
    let element=document?.getElementById(clickedMarker?.id)?.children[0]?.children[0]
    if(element)
      { 
        let rotation=
        clickedMarker.capturedImageMode === "panorama" && clickedMarker?.panoramaImages?.length > 1
           ?  
           clickedMarker?.panoramaImages[1]?.heading
           : clickedMarker.heading ? clickedMarker.heading : 0    
        element.style.color="white"
        element.style.transform=`rotate(${360-rotation}deg)`
      }
    document
      ?.getElementById(clickedMarker?.id)
      ?.classList.remove("yellow-marker-selected");
    document
      ?.getElementById(clickedMarker?.id)
      ?.classList.remove("empty-marker-selected");
    document
      ?.getElementById(clickedMarker?.id)
      ?.classList.remove("white-marker-selected");
    document
      ?.getElementById(clickedMarker?.id)
      ?.classList.remove("purple-marker-selected");
  }
  //add selected marker css style
  function addSelectedMarker(marker, el) {
    if (triggerOverlay === false) {
      if (toggledMarker !== undefined && isFormEdited === false) {
        tempData[marker?.location].length>1
          ? el?.classList.add("empty-marker-selected"):
        marker?.capturedImageMode === "panorama"
          ? el?.classList.add("yellow-marker-selected")
          : marker?.capturedImageMode === "waypoint"
          ? el?.classList.add("white-marker-selected")
          : el?.classList.add("purple-marker-selected");
      }
    } else if (triggerOverlay === true) {
      tempData[marker?.location].length>1
      ? el?.classList.add("empty-marker-selected"):
      marker?.capturedImageMode === "panorama"
        ? el?.classList.add("yellow-marker-selected")
        : marker?.capturedImageMode === "waypoint"
        ? el?.classList.add("white-marker-selected")
        : el?.classList.add("purple-marker-selected");
    }
  }
  //Check if previous popups of same marker are already added to map and remove them before adding current popup
  function checkAndRemovePreviousPopups(currentElement) {
    map.current?._markers.forEach((marker) => {
      if (marker?._element?.id === currentElement?._element.id) {
        marker?.remove();
      }
    });
  }
  //Function to remove preivious selected markers
  function removePreviousSelectedMarkers() {
    document
      .getElementsByClassName("white-marker-selected")[0]
      ?.classList.remove("white-marker-selected");
    document
      .getElementsByClassName("yellow-marker-selected")[0]
      ?.classList.remove("yellow-marker-selected");
    document
      .getElementsByClassName("purple-marker-selected")[0]
      ?.classList.remove("purple-marker-selected");
  }
  const getPopup = () => {
    const popupContent = (
      <TooltipBar
        markerData={clickedMarker}
        setClickedMarker={setClickedMarker}
        role={user?.role}
        setTriggerOverlay={setTriggerOverlay}
        triggerOverlay={triggerOverlay}
        setImgGallery={setImgGallery}
        imgGallery={imgGallery}
        setIsPopupDragging={setIsPopupDragging}
        currentElement={currentElement}
        setCurrentElement={setCurrentElement}
        getVisibiltyToggle={getVisibiltyToggle}
        currentMarkers={currentMarkers}
        removeSelectedMarker={removeSelectedMarker}
        setIsFormEdited={setIsFormEdited}
        overrideData={tempData[clickedMarker.location]}
        // getCurrentElementOnEdit={getCurrentElementOnEdit}
      />
    );

    const tempDOMElement = document.createElement("div");
    ReactDOM.createRoot(tempDOMElement).render(popupContent);
    tempDOMElement.setAttribute("id", clickedMarker?._element?.id);
    tempDOMElement.classList.add("custom-popup");
    tempDOMElement.addEventListener("mousedown", mouseDown, false);
    window.addEventListener("mouseup", mouseUp, false);

    function mouseDown(e) {
      if (e.target.classList.contains("drag-icon")) {
        e.preventDefault();
        offset = [
          e.clientX - tempDOMElement.offsetLeft + 10,
          e.clientY - tempDOMElement.offsetTop + 10,
        ];
        window.addEventListener("mousemove", moveElement, false);
      }
    }

    function mouseUp() {
      window.removeEventListener("mousemove", moveElement, false);
    }

    function moveElement(e) {
      const x = e.clientX - offset[0];
      const y = e.clientY - offset[1];
      tempDOMElement.style.left = `${x}px`;
      tempDOMElement.style.top = `${y}px`;
    }
    let popup = new mapboxgl.Popup({
      closeButton: false,
      anchor: "bottom",
    })
      .setDOMContent(tempDOMElement)
      .setMaxWidth("none")
      .setLngLat([0, 0])
      .setOffset(width >= 1300 ? [-35, 0] : [-30, 0])
      .on("close", () => {
        // Remove the 'active' class from the marker
        tempDOMElement.style.left = `0px`;
        tempDOMElement.style.top = `0px`;
        removeSelectedMarker();
        if (currentElement?._element?.classList[3] === undefined) {
          currentElement.setRotation(
            currentElement?._element?.attributes[1]?.nodeValue
              ? currentElement?._element?.attributes[1]?.nodeValue
              : 0
          );
        }
      });

    if (triggerOverlay === false) {
      currentElement.setRotation(null);
      currentElement?.setPopup(popup);
      checkAndRemovePreviousPopups(currentElement);
      //checking and removing revious opened markers with popup, removes selected class hence adding
      if (closeOverlay !== true) {
        addSelectedMarker(clickedMarker, currentElement?._element);
      }

      setCloseOverlay(false);
      currentElement?.addTo(map.current);
    }

    if (triggerOverlay === true) {
      currentElement?.getPopup()?.remove();
      checkAndRemovePreviousPopups(currentElement);
      addSelectedMarker(clickedMarker, currentElement?._element);
      currentElement?.addTo(map.current);
    }
  };
  useEffect(() => {
    if (map.current && currentElement && TooltipBarConfig) {
      getPopup();
    }
  }, [
    triggerOverlay,
    currentElement,
    clickedMarker,
    toggledMarker,
    width,
    isFormEdited,
  ]);

  function renderMarkers(markersData, fetchedData) {
    if (fetchedData && user) {
      const groupedMarkers = {};
      let newMarkers=[]

      for (const markerData of fetchedData) {
        // const sameMarkers = fetchedData.filter(
        //   (m) =>
        //     JSON.parse(m.location).latitude ===
        //       JSON.parse(markerData.location).latitude &&
        //     JSON.parse(m.location).longitude ===
        //       JSON.parse(markerData.location).longitude
        // );
        
        // if(newMarkers[0]?.id!=sameMarkers[0].id){
        //   const key = `${JSON.parse(markerData.location).latitude}_${
        //     JSON.parse(markerData.location).longitude
        //   }`;
  
        //   if (!groupedMarkers[key]) {
        //     groupedMarkers[key] = [];
        //   }
        //   groupedMarkers[key].push({...sameMarkers,groupId:JSON.parse(markerData.location).latitude});
        //   newMarkers=sameMarkers

        // }
        
        // create a HTML element for each markerData
        


        const el = document.createElement("div");
        // el.id=markerData.id
        let rotation=
           markerData.capturedImageMode === "panorama" && markerData?.panoramaImages?.length > 1
           ?  
           markerData?.panoramaImages[1]?.heading
           : markerData.heading ? markerData.heading : 0         
        el.setAttribute("id", markerData.id);
        if (
          tempData[markerData.location] &&
          tempData[markerData.location]?.length > 1
        )
          el.innerHTML = `<div style="position: relative;
            display: flex;
          ">
          <p 
            className = "cluster-text"
            style="line-height:1;
            font-size:14px;
            margin:auto;
            transform:rotate(${360-rotation}deg);
            padding-right:
            ${
             ( tempData[markerData.location].length>11 && tempData[markerData.location].length<20) || tempData[markerData.location].length===10
              ?
              "1px"
              :
              "0px"
            };
            padding-left:
            ${
              tempData[markerData.location].length%10===1 && tempData[markerData.location].length!==11 ? "1px" : "0px"
            }
          ">
          ${tempData[markerData.location].length}
          </p>
          </div>`;
        markerData.capturedImageMode === "panorama" &&
        markerData?.panoramaImages?.length > 1
          ? el.setAttribute(
              "heading",
              markerData?.panoramaImages[1]?.heading
                ? markerData?.panoramaImages[1]?.heading
                : 0
            )
          : el.setAttribute(
              "heading",
              markerData.heading ? markerData.heading : 0
            );
        if (
          tempData[markerData.location] &&
          tempData[markerData.location].length <= 1
        ) {
          el.className =
            markerData.capturedImageMode === "panorama"
              ? "yellow-marker"
              : markerData.capturedImageMode === "waypoint"
              ? "white-marker"
              : "purple-marker";
        } else {
          el.className = "empty-marker";
        }
        const location = JSON.parse(markerData.location);
        const coordinates = [location.longitude, location.latitude];
        // let zoomControl = new CustomZoomControl();

        //event Listener
        el.addEventListener("click", () => {
          setIsFormEdited(false);
          removePreviousSelectedMarkers();
          setClickedMarker(markerData);
          if(!TooltipBarConfig)
          setImgGallery(true)
          if(TooltipBarConfig)
          {
          if(tempData[markerData?.location]?.length>1)
          {
            el.children[0].children[0].style.color="black"
            el.children[0].children[0].style.transform="rotate(0deg)"
          }
          if (
            markerData.capturedImageMode === "panorama" &&
            markerData?.panoramaImages?.length >= 1
          ) {
            setCurrentElement(
              (newMarker = new mapboxgl.Marker(el)
                .setLngLat(coordinates)
                .setRotation(
                  markerData?.panoramaImages[1]?.heading
                    ? markerData?.panoramaImages[1]?.heading
                    : 0
                ))
            );
          } else {
            setCurrentElement(
              new mapboxgl.Marker(el)
                .setLngLat(coordinates)
                .setRotation(markerData.heading ? markerData.heading : 0)
            );
          }

          addSelectedMarker(markerData, el);}
        });
        let newMarker;
        if (
          markerData.capturedImageMode === "panorama" &&
          markerData?.panoramaImages?.length >= 1
        ) {
          newMarker = new mapboxgl.Marker(el)
            .setLngLat(coordinates)
            .setRotation(
              markerData?.panoramaImages[1]?.heading
                ? markerData?.panoramaImages[1]?.heading
                : 0
            );
        } else {
          newMarker = new mapboxgl.Marker(el)
            .setLngLat(coordinates)
            .setRotation(markerData.heading ? markerData.heading : 0);
        }
        newMarker.markerData=markerData
        markersData.push(newMarker);
      }
      
      // const groupedMarkersArray = Object.values(groupedMarkers);
      // setIsgroupedMarker(groupedMarkersArray)
      markersRef.current = markersData;
      copiedMarkerRef.current = markersData.map(function (marker) {
        return new mapboxgl.Marker(marker.getElement())
          .setLngLat(marker.getLngLat())
          .setRotation(marker.getRotation());
      });

      // ---------------- Clusters Code -------------------------
      // Set cluster properties
      // Load the markers into the Supercluster
      superCluster?.current?.load(
        markersRef?.current?.map(function (marker, index) {
          return {
            geometry: {
              type: "Point",
              coordinates: marker?.getLngLat().toArray(),
            },
            properties: { marker_id: index,marker_data:marker.markerData },
          };
        })
      );
        // Initial cluster rendering
        updateClusters(superCluster?.current);
    }
  }

  /* ---------------------------------------------------------------------------- */
  //UseEffect To Create instance of map
  useEffect(() => {
    getCenterCoordinates();
    if (map.current) return; //initialize map only once
    if (!mapboxgl.supported()) {
      alert("Your browser does not support Mapbox GL");
    } else {
      setLoading(true);
      // Initial Map Setting

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: user ? LAYERS.SETALLITEV9 : LAYERS.DARK1,
        projection: "globe",
        ...start,
        // -------------
        // center: [lng, lat],
        // zoom:1.5,

        // ----------------
        scrollZoom: false,
        dragRotate: false,
        doubleClickZoom: false,
        dragPan: false,

        // zoom: zoom,        // pitch: 45
      });

      // Listener on map
      map.current.on("load", () => {
        setLoading(false);

        spinGlobe();
        if (user) {
          if (
            document.querySelector(
              " .mapboxgl-ctrl-group .mapboxgl-ctrl-compass"
            ) == undefined
          ) {
            map.current.addControl(
              new mapboxgl.NavigationControl(),
              "bottom-right"
            );
          }
        }

        // if (user ) {

        //   if(map.current.getProjection()?.name==="globe"){
        //     let target = isAtStart ? end : start;
        //     map.current?.setProjection("mercator");
        //   setTimeout(() => {
        //     if(map.current.getProjection()?.name==="globe" && user){
        //       try {
        //         map.current?.doubleClickZoom.enable();
        //         map.current?.scrollZoom.enable();
        //         map.current?.dragRotate.enable();
        //         map.current?.dragPan.enable();

        //       } catch (error) {
        //         console.error("Network error:", error);
        //       }

        //     }

        //   }, 8000);
        //   map.current?.flyTo({
        //     ...target,
        //     duration: 8000,
        //     essential: true,
        //   });

        //   }

        //   if (
        //     document.querySelector(
        //       " .mapboxgl-ctrl-group .mapboxgl-ctrl-compass"
        //     ) === undefined
        //   ) {
        //     map.current.addControl(
        //       new mapboxgl.NavigationControl(),
        //       "bottom-right"
        //     );
        //   }
        //   // map.current.setStyle(LAYERS.ILLUMINATED1);
        // }
        var clusterRadius = 40; // Customize the cluster radius
        var clusterMaxZoom = 21; // Maximum zoom level at which clusters are generated
        // Create a Supercluster instance
        let supercluster = new Supercluster({
          radius: clusterRadius,
          maxZoom: clusterMaxZoom,
        });
        superCluster.current = supercluster;

        // Render Polygens
        // getCoordinatesAndRenderPolygons();
      });
      map.current.on("style.load", () => {
        if (!user) {
          map.current.setFog({
            color: "rgb(255, 255, 255)",
            "high-color": "rgb(255, 255, 255)",
            "horizon-blend": 0,
            "space-color": "rgb(255, 255, 255)",
          });
        }

        setIsMapLoaded(true);
      });
      map.current.on("moveend", () => {
        spinGlobe();
      });

      map.current.on("rotate", function () {
        var rotation = map.current?.getBearing(); // Get the current map rotation value
        if (copiedMarkerRef.current.length > 0) {
          for (let i = 0; i < copiedMarkerRef.current.length; i++) {
            const previousRotation = copiedMarkerRef.current[i]?.getRotation();
            markersRef.current[i].setRotation(
              (Math.abs(rotation) + previousRotation) % 360
            );
          }
        }
      });
    }
    return () => {
      map.current = null;
    };
  }, []);

  useEffect(() => {
    if (user) {
      const newTimeoutId = setTimeout(() => {
        if (user) {
          try {
            map.current?.doubleClickZoom.enable();
            map.current?.scrollZoom.enable();
            map.current?.dragRotate.enable();
            map.current?.dragPan.enable();
            map.current?.setProjection("mercator");
          } catch (error) {
            console.error("Network error:", error);
          }
        }
      }, 8000);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setTimeoutId(newTimeoutId);
      let target = isAtStart ? end : start;
      map.current?.flyTo({
        ...target,
        duration: 8000,
        essential: true,
      });
    } else if (!user) {
      superCluster?.current?.load([])
      map.current._markers=[]
      spinGlobe();
      try{
      map.current.easeTo({
        ...original,
        duration: 4000,
        speed: 0.2,
        essential: true,
        easing(t) {
          return t;
        },
      });
      map.current?.setProjection("globe");
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      map.current?.doubleClickZoom.disable();
      map.current?.scrollZoom.disable();
      map.current?.dragRotate.disable();
      map.current?.dragPan.disable();
    }catch(err){console.log(err)}
      const newTimeoutId = setTimeout(() => {
        try {
          map.current?.setStyle(LAYERS.DARK1);
          spinGlobe();
        } catch (error) {
          console.error("Network error:", error);
        }
      }, 4000);
      setTimeoutId(newTimeoutId);
    }
  }, [user]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  /* ---------------------------------------------------------------------------- */
  // UseEffect that Fetch Data for markers from API when Applied Filter Value Changes after user LoggedIn
  useEffect(() => {
    if (user && !loading) {
      getData(user);
    }
    if (currentElement) {
      currentElement?.getPopup()?.remove();
      setCurrentElement(null);
    }
  }, [
    selectedImageMode,
    startDate,
    endDate,
    currentMarkers,
    loading,
    imageFilterMarkers  ]);

  /* ---------------------------------------------------------------------------- */
  // UseEffect to add markers on map while Data Changed
  useEffect(() => {
    let markersData = [];
    markersData.length = 0;
    markersRef.current = markersData;

    if (loading === false) {
      renderMarkers(markersData, fetchedData);

      // Update clusters whenever the map changes
      map.current.on("moveend", handleMoveEnd);
    }
    // }
    //Remove the previous marker before new markers loading
    return () => {
      if (map.current) {
        map.current.off("moveend", handleMoveEnd);
      }
      if (markersData.length > 0) {
        markersData.forEach((marker) => {
          marker.remove();
        });
        markersData.length = 0;
      }
    };
  }, [fetchedData, user]);

  useEffect(() => {
    if (isMapLoaded && user && isRenderPolygon) {
      getCoordinatesAndRenderPolygons(user.role);
    }
  }, [currentSegments, isRenderPolygon]);

  return (
    <div className="map-container" ref={mapContainer} id="map">
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
        <symbol
          id="polygon-Icon"
          width="20"
          height="19"
          viewBox="0 0 20 19"
          fill="none"
        >
          <path
            d="M1.1759 7.63956L10 1.23559L18.8393 7.6506L15.7546 18H4.54595L1.1759 7.63956Z"
            stroke="white"
            strokeWidth="2"
          />
        </symbol>
        <symbol
          id="polygon-IconSelected"
          width="20"
          height="19"
          viewBox="0 0 20 19"
          fill="black"
          style={{
            width: "15px",
            marginTop: "3px",
          }}
        >
          <path
            d="M1.1759 7.63956L10 1.23559L18.8393 7.6506L15.7546 18H4.54595L1.1759 7.63956Z"
            stroke="black"
            strokeWidth="2"
          />
        </symbol>
      </svg>
      {loading ? (
        <div className="spinner-container">
          <Spinner animation="grow" />
        </div>
      ) : (
        <>
          {user ? (
            <>
              {markerDataLoading && (
                <div className="progress-bar-custom">
                  <div className="progress-bar-value"></div>
                </div>
              )}
              <div className="header-container">
                {!triggerOverlay && (
                  <Header
                    selectedImageMode={selectedImageMode}
                    setSelectedImageMode={setSelectedImageMode}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    currentMarkers={currentMarkers}
                    setCurrentMarkers={setCurrentMarkers}
                    imageFilterMarkers={imageFilterMarkers}
                    setImageFilterMarkers={setImageFilterMarkers}
                    role={user.role}
                    currentSegments={currentSegments}
                    setCurrentSegments={setCurrentSegments}
                  />
                )}
              </div>
              <div id={user.role === ROLES.USER? "toggleButton":"toggleButton-user"} onClick={toggleMapStyle}>
                <a>
                <img
                  src="/icons/Property 1=Default-Map-view.svg" 
                  alt="mapToggler"
                  style={{width:'100%' }}
                />
                <img
                  src="/icons/Property 1=Map-view-Hover.svg"
                  alt="mapToggler"
                  style={{ width:'100%'}}
                />
                </a>                
              </div>
              {/* hide Polygon Icon */}
              {/* {user.role != "user" && (
                <div
                  id="PolygonIcon"
                  className="polygonIcon"
                  onClick={togglePolygonStyle}
                  disabled={polygonDataLoading}
                  role="button"
                  aria-disabled={polygonDataLoading}
                  tabIndex={-1}
                >
                  {isRenderPolygon === false ? (
                    <svg viewBox="0 0 20 19">
                      <use href={"#polygon-Icon"}></use>
                    </svg>
                  ) : (
                    <svg viewBox="0 0 20 19">
                      <use href={"#polygon-IconSelected"}></use>
                    </svg>
                  )}
                </div>
              )} */}
              <div id= {user.role === ROLES.USER? "LogoutIcon":"LogoutIcon-user"}  onClick={handleLogout}>
                <a>
                <img
                  src="/icons/Property 1=Default-Log-out.svg" 
                  alt="logoutToggler"
                  style={{
                    width:'100%'                   
                  }}
                />
                <img
                  src="/icons/Property 1=Log-out-hover.svg"
                  alt="logoutToggler"
                  style={{
                    width:'100%'
                  }}
                />
                </a>                
              </div>
              {triggerOverlay && (
                <Overlay
                  clickedMarker={clickedMarker}
                  setTriggerOverlay={setTriggerOverlay}
                  triggerOverlay={triggerOverlay}
                  setImgGallery={setImgGallery}
                  removeSelectedMarker={removeSelectedMarker}
                  setCloseOverlay={setCloseOverlay}
                  role={user.role}

                  // popUpInstance={popUpInstance}
                ></Overlay>
              )}
              {imgGallery && (
                <CarouselComponent
                  clickedMarker={clickedMarker}
                  setTriggerOverlay={setTriggerOverlay}
                  setImgGallery={setImgGallery}
                  imgGallery={imgGallery}
                  overrideData={tempData[clickedMarker.location]}
                ></CarouselComponent>
              )}
            </>
          ) : (
            <LoginForm submitHandler={submitHandler} />
          )}
        </>
      )}

      {/* <div
        className="map-container"
        ref={mapContainer}
        id="map"
      > </div > */}
    </div>
  );
};

export default Dashboard;
